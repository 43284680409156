@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =================================My Screen========================== */

/*----------------------------------Header-----------------------------------------------*/

.h_new {
  background-color: #543379;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: sticky;
  top: 0;
  z-index: 5;
}

.h_left_icon_div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.h_menuicon_draweropen {
  cursor: pointer;
}

.h_white_logo {
  margin-left: 10px;
  object-fit: contain;
  /* background-color: #ffffff; */
}
.appsicon_cursor {
  cursor: pointer;
}
.notification_paper_shadow {
  z-index: 5;
  max-height: 400px;
  overflow: auto;
  /* border: 1px solid rgba(0,0,0,0.2);  */
  border-radius: 10px;
  width: 30%;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));
}

.webhome_tooltip_container_medical {
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  top: 5%;
  left: 88.4%;
  /* margin-left: -60px; */
  /* margin-top: 10px; */
  /* margin-top:90%; */
  /* margin-right: -40px; */
  /* border:1px solid red; */
  /* align-self: center; */
  height: 15vw;
  width: 20vw;
  /* background-color:#fec465; */
  /* border-radius: 50%; */
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vw;
}

.web_orange_tag_medical {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  height: 14vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.18);
  background-color: #ff5417;
  /* margin-right: -14px; */
  /*top:12%;*/
  top: 80px;
  right: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}

/*---------------------------END Header--------------------------------*/

/*---------------------------Left  drawer-----------------------------*/
.ButtonBGNeeds {
  width: 218px;
  height: 34px;
  border-radius: 30px;
  border: solid 2px #eb5757;
}
.captionNeeds {
  width: 203px;
  height: 24px;
  font-family: Tungsten;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  text-align: center;
  color: #eb5757;
}
.leftdrawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 100px;
  /* padding-left: 20px; */
}
.ld_user_div {
  /* border:1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
}
.ld_username {
  margin-left: 10px;
  /* width: 155px; */
  /* height: 22px; */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: #000000;
  /* border: 1px solid blue; */
  text-transform: capitalize;
}
.ld_menu_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border:1px solid blue; */
  width: 100%;
  /* margin-bottom: 5px; */
  cursor: pointer;
  padding: 10px 0px;
  padding-left: 20px;
}

.ld_menu_container_selected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border:1px solid red; */
  width: 100%;
  /* margin-bottom: 5px; */
  cursor: pointer;
  padding: 10px 0px;
  padding-left: 20px;
  background-color: #e5e7ea;
  border-right: 5px solid #543379;
}
.ld_image_div_container {
  /* border:1px solid red; */
  width: 50px;
}

.ld_menu_name {
  /* width: 73px; */
  /* height: 24px; */
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid green;
}
/*-------------------------------END left drawer------------------------------*/

/* =======================Right Drawer=================== */

.rightdrawer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  padding-top: 5vw;
  padding-left: 40px;
  padding-right: 40px;
  /* border: 1px solid red; */
}

.rightdrawer_card {
  width: 100px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  /* margin:10px; */
  margin-right: 30px;
  margin-top: 40px;
  cursor: pointer;
}

.rightdrawer_card_name {
  margin-top: 1vw;
  /* width: 119px; */
  /* height: 40px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.5px;
  color: #333333;

  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* border: 1px solid red; */
}

/* =======================END Right Drawer=================== */

/* ==================================Dashboard============================= */

.webhome_container {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-items: center; */
  /* align-items: center; */
  /* border: 1px solid red; */
}
.webhome_middle_image {
  height: 300px;
  object-fit: contain;
  z-index: -2;
}
.webhome_all_card_main_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  /* border:1px solid red; */
}

.demodrawer {
  background-color: #ffffff;
  width: 400px;
  height: 700px;
}
.webhome_left-top_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  border: 1px solid green;
  width: 990px;
}

.webhome_left_bottom_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* align-items: center; */
  /* border:1px solid red; */
  /* width: 48vw; */
}
.webhome_left_div {
  border: 1px black;
}
.webhome_center_div {
  border: 1px solid red;
}
.webhome_right_div {
  width: 320px;
  border: 1px black;
}
.webhome_centercard_container {
  /* border:1px solid blue; */
  /* width: 50%; */
  /* height: auto; */
}

.commoncard {
  background-color: #ffffff;
  height: 200px;
  width: 310px;
  margin: right;
}
.web_top_div {
  display: flex;
  justify-content: space-between;
  /* border:1px solid red; */
}
.web_middle_container {
  position: absolute;
  z-index: -1000;

  display: flex;
  /* border:1px solid red; */
}
.myneeds_top_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4vw;
  /* height: 60px; */
  /* background-color: cadetblue; */
  padding: 0vw 0.8vw;
}
.myneeds_header_text {
  /* border: 1px solid red; */
  flex: 1;
  margin: 0vw 1.3vw;

  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
}

.webhome_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: -2;
  border: 1px solid blue;
}
.webhome_backgrond_vector {
  width: 704px;
  height: 544px;
  object-fit: contain;
}
.webhome_onover_group_image {
  position: absolute;
  top: 10%;
  left: -5%;
}

.webhome_username_container {
  margin-left: -150px;
  margin-top: -70px;
  /* position: absolute; */
  /* border:1px solid red; */
  z-index: 999;
}

.webhome_username_text {
  font-family: 'Anton', sans-serif;
  /* font-family: 'Bebas Neue', cursive; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Ranchers', cursive; */

  width: 566px;
  height: 166px;
  /* font-family: Tungsten; */
  font-size: 55px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  word-spacing: 0.2;
  color: #000000;
}

.webhome_welcome_back_text {
  color: red;

  /* font-family: 'Ranchers', cursive; */
  font-size: 24px;
}

.web_welcome_image {
  /* width: 704px;
  height: 490px; */

  /* width: 21vw;

  height: 216px; */
  margin-left: -23vw;
  width: 93vw;
  height: 57vh;
  max-width: 121%;
  margin-top: -10vh;

  /* height: 490px; */
  /* object-fit: contain; */
  /* border: 2px solid black; */
  z-index: 0;
}
.web_middle_seconddiv {
  /* margin-top: -70px; */

  margin-top: 3vw;

  /* border:1px solid red; */
  align-self: center;
  /* margin-left: -9vw; */
  /* align-items: center;
  justify-content: center; */
  z-index: 0;
}
.web_welcome_back {
  /* margin-bottom: -20px; */
  margin-bottom: -1.2vw;
  /* width: 20vw; */
  max-width: 150%;
  width: 21vw;
}
.fontStyles {
  font-family: Roboto !important;
  /* margin: 11px 0px 0px 11px !important; */
}
.number-View-download {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  cursor: pointer;
  font-style: Roboto;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  color: #AB5576 !important;
}
.number-View-download-disabled {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: Roboto;
  cursor: not-allowed;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  color: grey !important;
}
.reimbursement-ButtonBGsave {
  width: 93px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px;
  border: solid 1px;
  background-color: #3fb6c2; 
  color: #000000;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.footerIcons {
  display: flex;
  font-family: Roboto !important;
  margin: 0px 0px 0px 0px !important;
  flex-direction: row;
  /* z-index: 1029; */
  align-content: center;
  justify-content: center;
  align-items: center;
}
.web_top_username {
  /* border:1px solid blue; */
  /* margin-left: -24vw; */

  text-transform: uppercase;
  width: 33vw;
  height: 166px;
  /* width: 566px;
  height: 166px; */
  font-family: 'Anton', sans-serif;
  /* font-size: 72px; */

  /* font-size: 5vw; */
  font-size: 6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000000;
}
.web_tooltiop_member_container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.web_orange_tag {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  z-index: 1;
  height: 13.5vw;
  box-shadow: 72px -53px 21px 72px rgb(0 0 0 / 24%), 0 9px 18px 0 rgb(0 0 0 / 18%);
  background-color: #ff5417;
  margin-right: -14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_orange_tag_dis {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  z-index: 1;
  height: 13.5vw;
  box-shadow: 72px -53px 21px 72px rgb(0 0 0 / 24%), 0 9px 18px 0 rgb(0 0 0 / 18%);
  background-color: #989897;
  margin-right: -14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  position: fixed;
}
.web_yellow_tag {
  width: 4vw;
  height: 15vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.18);
  background-color: #eb5757;
  margin-right: -14px;
  display: flex;
  flex-grow: 0;
  margin-top: 13.5vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_orange_tagh {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  z-index: 1;
  height: 13.5vw;
  box-shadow: 72px -53px 21px 72px rgb(0 0 0 / 24%), 0 9px 18px 0 rgb(0 0 0 / 18%);
  background-color: #ff5417;
  margin-right: -22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_orange_tagh_dis {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  z-index: 1;
  height: 13.5vw;
  box-shadow: 72px -53px 21px 72px rgb(0 0 0 / 24%), 0 9px 18px 0 rgb(0 0 0 / 18%);
  background-color: #989897;
  margin-right: -22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  position: fixed;
}
.web_yellow_tagh {
  width: 4vw;
  height: 15vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.18);
  background-color: #eb5757;
  margin-right: -22px;
  display: flex;
  flex-grow: 0;
  margin-top: 13.5vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_patch_icon {
  object-fit: contain;
  /* width: 36px;
  height: 33px; */
  margin-bottom: 18px;
}
.web_patch_icon_yellow {
  object-fit: contain;
  width: 32.5px;
  height: 20.8px;
  margin-bottom: 30px;
}
.web_patch_text {
  /* justify-self: flex-end;
  border:2px solid blue;
  width: 140px;
  display:flex;
  font-size:18px;
  color:#ffffff;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); */
  /* margin-top: 20px; */
  /* border:2px solid blue; */
  margin-top: 1.2vw;
  /* width: 140px;
  height: 21px; */
  width: 11vw;
  height: 2vw;
  transform: rotate(-90deg);
  font-family: Roboto;
  /* font-size: 18px; */
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.web_patch_text_yellow {
  /* justify-self: flex-end;
  border:2px solid blue;
  width: 140px;
  display:flex;
  font-size:18px;
  color:#ffffff;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); */
  /* margin-top: 20px; */
  /* border:2px solid blue; */
  margin-top: 2.8vw;
  /* width: 140px;
  height: 21px; */
  width: 11vw;
  height: 2vw;
  transform: rotate(-90deg);
  font-family: Roboto;
  /* font-size: 18px; */
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

/*---------------------------END Dashboard--------------------------------*/

/* =======================Home.js.js======================= */

.background-full-size {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: #eae8db;
}
.top-horizontal-margin {
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 30px;
}
.cardmaincontainer {
  height: 500px;
  width: 570px;
  background-color: tomato;
}
.cardmaincontainer2 {
  height: 500px;
  width: 370px;
  background-color: tomato;
}

/* =====================Header.js============== */
.header-div {
  background-color: #420045;
  color: white;
}

/* =====================MyNeedsCard.js================ */
.myneedscard-div {
  height: 200px;
  width: 310px;
  background-color: #ffffff;
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  margin: 10px;
  cursor: pointer;
}

.My-Needs-header {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.bg_content {
  width: 310px;
  height: 100px;
  background-color: rgba(234, 232, 219, 0.4);
}
.circle-logo {
  background-color: #f0c8a0;
  width: 36px;
  height: 36px;
  border-radius: 30px;
}
.date {
  /* width: 95px; */
  /* height: 20px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  /* font-stretch: normal; */
  /* font-style: normal; */
  /* line-height: 1; */
  letter-spacing: normal;
  color: #8c827a;
}
.VIEW-DETAILS {
  /* width: 93px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

.PENDING {
  /* width: 52px; */
  /* height: 23px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #eb5757;
}
.APPROVED {
  /* width: 75px; */
  /* height: 23px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #27ae60;
}

.Activity-timeline {
  /* width: 176px; */
  /* height: 29px; */
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #4e4e4e;
}

/* ===================================card design ============= */
.Card-BG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 145px;
  height: 145px; */
  width: 11vw;
  height: 11vw;
  /* height: 145px; */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  /* margin-top: 20px; */
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  /* margin: 10px; */
  margin: 0.5vw 0.5vw;
  cursor: pointer;
}
.memberID {
}
.NotificationCard-BG {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */

  /* width: 145px;
  height: 145px; */
  width: 23vw;
  height: 4vw;
  /* height: 145px; */
  border-radius: 4px;
  /* box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd; */
  /* border-bottom: 1px solid lightgray; */
  background-color: #ffffff;
  /* margin-top: 20px; */
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  /* margin: 10px; */
  /* margin: 0.5vw 0.5vw; */

  /* cursor: pointer; */
}
.DisplayNotification_Card-BG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* width: 145px;
  height: 145px; */
  width: 100%;
  height: auto;
  /* height: 145px; */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  /* margin-top: 20px; */
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  /* margin: 10px; */
  margin: 0.5vw 0.5vw;
  cursor: pointer;
}
.maincard_image_icon {
  width: 4vw;
  height: 4vw;
}
.cardmarginleftright {
  margin-right: 20px;
  margin-left: 20px;
}

.captiontextdiv {
  /* width: 132px; */
  /* height: 36px; */
  /* font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.15px;
    color: #4f4f4f; */
  /* width: 118px; */
  /* height: 33.8px; */
  /* font-family: Roboto; */
  width: 9vw;
  /* font-size: 18px; */
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.15px;
  color: #5f2161;
  /* border:1px solid red; */
  margin-top: 10px;
}
.DisplayNotification_captiontextdiv {
  /* width: 132px; */
  /* height: 36px; */
  /* font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.15px;
    color: #4f4f4f; */
  /* width: 118px; */
  /* height: 33.8px; */
  /* font-family: Roboto; */
  /* width: 9vw; */
  /* font-size: 18px; */
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.15px;
  color: #5f2161;
  /* border:1px solid red; */
  margin-top: 10px;
  padding-left: 2%;
  padding-left: 2%;
  padding-bottom: 20px;
  padding-top: 20px;
}
.cationdiv {
  width: 132px;
  /* height: 36px; */
}

/* =====================Footer.js============================ */
.Copyright-2020-Universal-Health-Fellowship-All {
  /* width: 511px; */
  height: 16px;
  font-family: PalatinoLinotype;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 20px;
}
/* =============================DocumentsCard.js======================= */
.Important-Documents {
  /* width: 82px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
}
.myneeds_header_image {
  width: 2vw;
  /* width: 36px;
      height: 32px;
      object-fit: contain; */
}
.bg_content_doc {
  width: 310px;
  height: 100px;
  background-color: rgba(234, 232, 219, 0.4);
  overflow: scroll;
  overflow-x: hidden;
}
.Single-line-item {
  /* width: 190px; */
  /* height: 19px; */
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #4f4f4f;
  cursor: pointer;
}
.Single-line-item:hover {
  background-color: #5f2161;
  color: #ffffff;
}
.printCard {
  display: 'none';
}
/* ======================MyShareplusCard-===================== */
.mysharecard {
  width: 310px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  margin: 10px;
  cursor: pointer;
}
.myshareplus_bg_content {
  width: 310px;
  height: 100px;
  background-color: rgba(234, 232, 219, 0.4);
  padding-right: 19px;
  padding-left: 19px;
}
.myshareplus_dollor_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Income {
  /* width: 300px; */
  /* height: 19px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}
.dollorprice {
  /* width: 100px; */
  /* height: 21px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #f6356a;
}
.dollorfullrange {
  /* width: 125px; */
  /* height: 21px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #4f4f4f;
}

/* Announcement Modal footer button--------- */
.announcementFooterButton {
  width: 150px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px !important;
  border: solid 2px #41b5c2 !important;
  background-color: #41b5c2 !important;
  color: #ffffff !important;

  font-family: Tungsten;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.announcementFooterButtonDisable {
  width: 150px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px !important;
  border: solid 2px #c7c4c4 !important;
  background-color: #c7c4c4 !important;
  color: #ffffff !important;

  font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}

/* ============================Contact info================= */
.contactinfocard {
  /* width: 310px;
  height: 370px; */
  width: 23vw;
  height: 23vw;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  /* margin: 10px; */
  /* margin: 0.5vw; */
  /* border: 1px solid red; */
}
.contact_infomiddiv {
  /* width: 310px; */
  /* height: 269px; */
  /* to set footer at contact  height: 17vw; */

  height: 19vw;

  background-color: rgba(234, 232, 219, 0.4);
  overflow: hidden;
  overflow-y: scroll;
  padding: 0.5vw 1.5vw;
}
.contactinfo_label {
  /* width: 304px; */
  /* height: 18px; */
  /* font-family: 'Roboto'; */
  /* font-size: 15px; */
  /* font-size: 1vw; */
  font-size: 0.8vw !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #4f4f4f;
  margin-top: 1vw;
}

.contactinfo_Value {
  /* width: 164px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  /* font-size: 20px; */
  /* font-size: 1.3vw; */
  font-size: 1vw !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #33ab8f;
  word-break: break-all;
}
.contactcard_footer {
  height: 2vw;
  /* border:1px solid red; */
}
.card_threedot {
  /* border: 1px solid red; */
  height: 1.5vw;
  width: 1.5vw;
}

.documents_infomiddiv {
  /* width: 310px; */
  /* height: 269px; */
  /* to set footer at document card set height 17vw*/
  /* height: 17vw; */
  height: 19vw; /*15vw for ai*/
  background-color: rgba(234, 232, 219, 0.4);
  overflow: hidden;
  overflow-y: scroll;
  padding: 0.5vw 0vw;
  /* padding: 0.5vw 1.5vw; */
}

.documentscardnew_welcome_div {
  /* border:1px solid red; */
  padding: 1vw 1.5vw;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #4f4f4f;
  /* margin-top: 1vw; */
}
.documentscardnew_welcome_div:hover {
  background-color: #5f2161;
  color: white;
  cursor: pointer;
}

.documentscardnew_welcome_div_Disable {
  /* border:1px solid red; */
  padding: 1vw 1.5vw;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #999494;
  /* margin-top: 1vw; */
}
.demodivcont {
  width: 140px;
  height: 140px;
  background-color: #ffffff;
}

.bottommaincontainer_left {
  width: 520px;
  /* height: 140px; */
  /* background-color: red; */
  margin-right: 20px;
}

.bottommaincontainer_right {
  width: 140px;
  height: 140px;
  background-color: #ffffff;
}

/* ===============================================MYNeeds.js======================== */

.container_div {
  display: flex;
  justify-content: center;
}
.myneeds_maincontanier {
  width: 980px;
  /* height: 580px; */
  border-radius: 4px;
  background-color: #ffffff;
}
.myneeds_header {
  /* width: 91px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}
.Submitted-Expenses {
  /* width: 200px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}
.pending_table {
  /* width: 60px; */
  /* height: 16px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #c8372d;
  background-color: #feedec;
  border-radius: 4px;
}
.deined_table {
  /* width: 50px; */
  /* height: 16px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #ce6b00;
  background-color: #fef3e7;
  border-radius: 4px;
}
.approve_table {
  /* width: 70px; */
  /* height: 16px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #37833b;
  background-color: #edf6ee;
  border-radius: 4px;
}
.table_text {
  /* width: 345px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  color: #333333;
}
.table_custom_grey {
  background-color: #f9f9fa;
}
.subtable_title {
  /* width: 128px; */
  /* height: 16.6px; */
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.56);
}
.subtable_rowdata {
  /* width: 94px; */
  /* height: 20px; */
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}
.subtable_view {
  /* width: 50px; */
  /* height: 20px; */
  /* font-family: Roboto; */
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #8c1342;
}

.table_header_text {
  /* width: 83px; */
  /* height: 22px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.1px;
  color: #8c827a;
}

/* ===========================SharePlusAccount===================== */
.shareplus {
  width: 980px;
  height: 580px;
  border-radius: 4px;
  background-color: #ffffff;
}
.shareplus_rangediv {
  display: flex;
  flex: 1;
  justify-content: center;
}
.shareplus_range_header {
  /* width: 400px; */
  /* height: 19px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}

/* =================================Member Header================== */
.memberheader {
  display: flex;
  width: 100%;
  height: 75px;
  background-color: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
  align-items: center;
}
.memberheader_img {
  width: 150px;
  height: 50px;
  object-fit: contain;
}
.memberheader_assologodiv {
  width: 150px;
  height: 50px;
  background-color: #eeeeee;
  /* justify-content: center; */
  /* align-items: center; */
}
.memberheader_logotext {
  /* width: 111px; */
  /* height: 40px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

.membersubheader {
  width: 100%;
  height: 47.3px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #33afb0;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}
.membersubheader_header {
  /* width: 121px; */
  /* height: 24px; */
  /* font-family: Candara; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #ffffff;
}

.welcomemsg {
  width: 302px;
  height: 50px;
  font-family: Candara;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.15px;
  color: #f6356a;
  margin-left: 24px;
}
.member_subtext {
  width: 80%;
  /* height: 32px; */
  font-family: PalatinoLinotype;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 24px;
  margin-left: 24px;
}
.member_cardcontainer {
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 22px;
}
.member_cardrow {
  display: flex;
  margin-right: 14px;
  margin-left: 14px;
}
.member_carddiv {
  width: 225px;
  height: 188px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  margin-right: 25px;
}
.membercardimg {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.membercardtext {
  /* width: 195px; */
  /* height: 51px; */
  /* font-family: Candara; */
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

/* ============================Medical======================= */
.medical {
  width: 975px;
  /* height: 494px; */
  background-color: #ffffff;
  /* padding-left: 25px; */
  /* padding-right: 25px; */
  padding-top: 10px;
}
.medical_horizontal_padding {
  padding: 0px 25px;
}
.medical_top_div {
  margin-top: 100px;
}
.medical_header {
  width: 525px;
  height: 72px;
  /* font-family: Roboto; */
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #543379;
}
.medical_subtext {
  /* width: 1181.5px; */
  height: 58.3px;
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}
.medical_container {
  display: flex;
  align-items: center;
}
.medical_name_col {
  width: 140px;
  /* border:1px solid red; */
}
.medical_status_col {
  width: 300px;
  /* border:1px solid red; */
}
.medical_button_col {
  width: 90px;
  /* border:1px solid red; */
}
.medical_last_col {
  width: 140px;
  /* border:1px solid red; */
}
.medical_table_header {
  /* width: 49.9px; */
  height: 27px;
  font-family: Tungsten;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #454d58;
}
.medical_table_col1 {
  width: 137.8px;
  height: 22.8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}
.medical_btn_col {
  width: 90px;
  height: 29px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid #41b5c2;
}
.medical_table_col3 {
  width: 66px;
  height: 24px;
  font-family: Candara;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #41b5c2;
}
.medical_table_col4 {
  /* width: 128px; */
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #4f4f4f;
}
.medical_donebtn {
  font-family: Tungsten;
  /* font-family: Candara; */
  width: 120px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid;
  background-color: #41b5c2;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medical_donebtn_mobile {
  font-family: Tungsten;
  /* font-family: Candara; */
  width: 30vw;
  height: 40px;
  border-radius: 20px;
  border: 1px solid;
  background-color: #41b5c2;
  /* font-size: 15px; */
  /* font-size: 4.4vw; */
  font-size: 100%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medical_help {
  width: 56px;
  height: 56px;
  border-radius: 99px;
  box-shadow: 0 2px 4px 0 #bdbdbd;
  background-color: #41b5c2;
}

.medical_help_icon {
  width: 30px;
  height: 30px;
  color: #ffffff;
}
.medical_needhelp {
  /* width: 272px; */
  /* height: 47px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #333333;
}

.medical_needmsg {
  /* width: 272px; */
  /* height: 47px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  /* color: #7e7373; */
  /* color: #dfdfdf; */
  color: #333333;
}

.medical_bottom_container {
  /* border:1px solid red; */
  margin-top: 40px;
  padding: 0px 25px;

  height: 120px;
  background-color: rgba(223, 223, 223, 0.25);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.medical_need_help_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.medical_help_text_div {
  /* border:1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 20px;
}

/* ===================Digital Health Catd======================== */
.digital_healthcard {
  padding: 10px;
}
/* .column1 {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
.divCenter {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.modal-90w {
  max-width: 100% !important;
  /* border:10px solid red; */
}
.modal-content {
  padding: 0rem;
}

.card {
  /* width: 60%; */
  margin: 5% auto;
  /* height: 30% ; */
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 100%;
  min-height: 80%;
  /* background: url('../Images/group-51@3x.png') ; */
}
.halfCircle {
  shape-outside: ellipse(100px 200px at 50% 50%);
  width: 300px;
  height: 500px;
  float: left;
  background-color: lighten(purple, 60);
  opacity: 0.2;
}
.halfCircleRight {
  height: 90px;
  width: 45px;
  border-radius: 0 90px 90px 0;
  background: green;
}
.xxxxx {
  color: #98335b;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
  font-family: Roboto;
}
.row1 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 40%;
  margin-left: -15px;
}
.row_main {
  margin-top: 15px;
  margin-left: 55px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  font-family: Roboto;
}
.col2Head {
  margin-top: 15px;
  font-weight: bold;
  font-size: 20px;

  font-family: Roboto;
}
.sub-row {
  margin-top: 15px;
  margin-left: 55px;
  /* font-weight:bold; */
  font-size: 14px;
  color: #98335b;
  font-weight: 500;

  font-family: Roboto;
}
.tdData {
  margin-top: 15px;
  margin-left: 55px;
  font-weight: bold;
  font-size: 14px;
  color: #8e3c90;

  font-family: Roboto;
}
.backP3 {
  /* margin-top:-19px; */
  margin-top: 15px;
  margin-left: 55px;
  font-weight: bold;
  font-size: 16px;
  color: #8e3c90;
  display: flex;
  justify-content: space-around;
  font-family: Roboto;
}
.website {
  margin-top: 15px;
  margin-left: 20px;
  /* font-weight:bold; */
  font-size: 12px;
  color: #000000;
  margin-right: 20px;
  font-family: Roboto;
}
.thanku {
  margin-top: 15px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  font-family: Tungsten-bold;
}
.sharing {
  font-family: WildernessTypeface-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e9716f;
}
.content_center {
  /* margin-top:-19px; */
  margin-top: 15px;
  /* margin-left:55px; */
  /* margin-right:55px; */
  /* font-weight:bold; */
  /* font-size:18px; */
  font-size: 14px;
  display: flex;
  text-align: justify;
  justify-content: space-around;
  font-family: Roboto;
  /* color:#8e3c90 */
}
.row_sub {
  margin-top: 15px;
  margin-left: 55px;
  font-weight: bold;
  font-size: 14px;
  color: #942b66;

  font-family: Roboto;
}
.content_header {
  margin-top: 15px;
  /* margin-left:55px; */
  font-weight: bold;
  /* margin-right:55px; */
  text-align: justify;
  /* font-size:18px; */
  font-size: 13px;
  color: #543379;
  display: flex;
  justify-content: space-around;
  font-family: Roboto;
}
.content_footer {
  margin-top: 15px;
  margin-left: 55px;
  font-weight: bold;
  margin-right: 55px;
  text-align: center;
  /* font-size:18px; */
  font-size: 12px;
  /* color:#af48a8; */
  display: flex;
  justify-content: space-around;
  font-family: Roboto;
}
.back_button {
  width: 150px;
  height: 24px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #4f4f4f;
  margin-left: 80%;
  cursor: pointer;
}
.column1 {
  flex: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

.row {
  display: flex;
}
.bg_image {
  /* backgroundImage:"url(" + Background + */
  background-image: url(../Images//group-51@2x.png);
  background-repeat: no-repeat;
  /* transform: rotate(180deg); */
  object-fit: contain;
}
.row:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 768px) {
  .backP1,
  .backP2,
  .backP3 {
    flex-direction: column;

    /* left: 20px; */
    /* position: relative; */
    /* align-items: inherit; */
    /* max-width: 100%; */
    /* width: 100%; */
  }
  /* .card{
    max-width: 720px;
  } */
}
@media screen and (max-width: 600px) {
  .column1 {
    width: 100%;
  }
}
@media screen and (max-width: 1366px) {
  .card {
    width: 80%;
    min-width: 90%;
    min-height: 80%;
    /* border:1px solid red; */
  }
  .modal-90w {
    max-width: 60% !important;
    /* border:10px solid red; */
  }
}
/* @media (min-width: 576px){
.card {
    max-width: 540px;
}
} */

.digital_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.digital_content {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.digital_texthead {
  font-size: 14px;
  font-weight: 600;
  color: #4f4f4f;
}

.digital_blueview {
  display: flex;
  justify-content: space-between;
  width: 320px;
}
.digital_bluetext {
  font-size: 18px;
  color: #7ec9b8;
  font-weight: 500;
}
.digital_thankyou {
  margin-top: 50px;
  font-size: 16px;
  color: #f74879;
  font-weight: 600;
}
.digital_rightview {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 60px;
}
.digital_joined {
  font-size: 14px;
  color: black;
}
.digital_date {
  font-size: 16px;
  color: #972954;
  font-weight: 700;
  width: 100px;
}
.digital_footer {
  background-color: #420046;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}
.digital_footer_text {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.digital_bottom {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}
.digital_viewcard {
  font-size: 14px;
  color: #919191;
  font-weight: 500;
}
.digital_download {
  font-size: 14px;
  color: #80689b;
  font-weight: 500;
}
.cursor_pointer {
  cursor: pointer;
}
.digital_health_card_top_logo {
  object-fit: contain;
  height: 100px;
}
#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

/* ====================================Dashboard.js==================== */
/* .container_div{
    display: flex;
    justify-content: center;
  } */

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard_heading_container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashbaord_username {
  /* width: 278px; */
  height: 45.9px;
  /* font-family: Roboto; */
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4f4f4f;
}
.dashboard_subtext {
  margin-top: 10px;
  /* width: 278px; */
  height: 30.6px;
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}

.dashboard_inputdiv {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  width: 463px;
  height: 60px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.dashboard_input_message {
  outline-width: 0px;
  border: none;
  width: 280.6px;
  height: 16px;
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}

.dashboard_horizontal_card {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_find_doctor {
  width: 119px;
  height: 58px;
  border-radius: 29.5px;
  border: solid 1px #dfe1e5;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_find_doctor_text {
  margin-left: 10px;
  width: 46px;
  height: 36px;
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #000000;
}

.dashboard_call_customer {
  width: 158px;
  height: 58px;
  border-radius: 29.5px;
  border: solid 1px #dfe1e5;
  background-color: #ffffff;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
}

.dashboard_call_csutomer_text {
  width: 105px;
  height: 36px;
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #000000;
}

.dashboard_notification_check {
  width: 162px;
  height: 58px;
  border-radius: 29.5px;
  border: solid 1px #dfe1e5;
  background-color: #ffffff;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dashboard_notification_text {
  width: 86px;
  height: 36px;
  /* font-family: Roboto; */
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #000000;
}

.dashboard_card_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ===============================Notification Card.js=============== */

.notification {
  margin-top: 14px;
  margin-left: 20px;
  width: 340px;
  height: 160px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
}

.notification_top_container {
  height: 50px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.notification_header {
  /* width: 129px; */
  height: 18px;
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

.notification_bottom_container {
  width: 340px;
  height: 110px;
  padding: 10px 10px;
  background-color: rgba(234, 232, 219, 0.4);
}

.notification_row {
  display: flex;
  margin-top: 8px;
}
.notification_row1 {
}
.notification_row2 {
  flex: 1;
  /* border: 1px solid red; */
  margin-left: 10px;
}
.notification_love_background {
  background-color: #f5887f;
  width: 36px;
  height: 36px;
  border-radius: 29px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.notification_time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification_topsmall {
  /* width: 93.7px; */
  height: 20px;
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #8c827a;
}
.notification_tagline {
  margin-top: -5px;
  /* width: 263px; */
  /* height: 31px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #4e4e4e;
}

.notification_question_background {
  background-color: #304d63;
  width: 36px;
  height: 36px;
  border-radius: 29px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_copyright {
  margin-top: 60px;
  margin-bottom: 20px;
  width: 511px;
  height: 16px;
  font-family: PalatinoLinotype;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333;
}

.h_container {
  background-color: #eae8db;
  /* height: 400px; */
}
.h_header_text {
  /* width: 200px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #ffffff;

  margin-left: 10px;
}

/* =================== */

.leftdrawer_logo {
  width: 171px;
  /* height: 77px; */
  object-fit: contain;
}
.leftdrawer_username {
  /* width: 165px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);

  margin-top: 20px;
  margin-bottom: 20px;
}
.leftdrawer_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.leftdrawer_menu_name_selected {
  /* width: 68px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #420045;

  margin-left: 20px;
}

.leftdrawer_menu_name {
  /* width: 81px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);

  margin-left: 20px;
}

.login_container {
  /* border:1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_image_container {
  /* border:1px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.login_uhs_logo {
  /* margin-top: 30px; */
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: -160px;
  height: 68px;
  object-fit: contain;
  /* width: 252px; */
}
.login_main_image {
  /* height: 553px; */
  height: 400px;
  object-fit: contain;
}
.login_input_container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}
.login_input_username {
  outline-width: 0px;
  border: none;
  padding: 20px 20px;
  /* border:1px solid #89969f; */
  width: 374px;
  height: 66px;
  border-radius: 100px;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);

  /* font-family: 'Tungsten'; */
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;

  margin-right: 30px;
}

.login_button {
  width: 176px;
  height: 65px;
  border-radius: 50px;
  background-color: #e9716f;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  margin-bottom: 20px;
}
.login_error {
  font-size: 14px;
  color: red;
  margin-left: 20px;
  margin-top: 5px;
}

.login_button:hover {
  border: none;
  outline: none;
}
.login_button:focus {
  border: none;
  outline: none;
}

.login_plese_text {
  font-weight: 500;
  color: #e9716f;
}

/* ==============================Loginform.js================= */
.loginform {
  border: 1px solid red;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: #ffffff;
  /* background-color: #27ae60; */
}

.loginform_container {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mycard {
  /* width: 130px; */
  /* height: 195px; */
  position: relative;
  display: inline-block;
  /* margin: 50px; */
}
.mycard .myimg-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.mycard:hover .myimg-top {
  display: inline;
}

.dash {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dash_drawer {
  width: 400px;
  height: auto;
  background-color: honeydew;
}

/*
.box_yellow{

  position: absolute;
   top: 0;
  right: 0;
  opacity: 1.8;
}
.stack-top_yellow{
  z-index: 9;
}

.yellow_popup_caption {
  position: absolute;
  top: 50%;
  left: 73%;
  text-align: center;
  color: #000000;
  font-weight: bold;
}
.yellow_popup_caption_button {
  position: absolute;
  border-radius: 20px;
  top: 71%;
  left: 26%;
  right:26%;
  background-color: #eb5757;
  color: #fff;
  font-weight: 500;
  font-size:20px;;
  text-align: center;

}
.yellow_popup_caption2 {
  position: absolute;
  top: 55%;
  left: 71%;
  right:9%;
  text-align: center;
  color: #000000;
  font-weight: 400;
  word-spacing: -2px;
} */

.webhome_tooltip_container {
  background-image: url('../Images/callout_02.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  top: 83px;
  right: 55px;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));

  z-index: 1;

  /* margin-left: -60px; */
  /* margin-top: 10px; */
  /* margin-top:90%; */
  /* margin-right: -40px; */
  /* border:1px solid red; */
  /* align-self: center; */
  height: 15vw;
  width: 22vw;
  /* background-color:#fec465; */
  /* border-radius: 50%; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5vw;
}

.web_checkoutboldtext {
  font-size: 1.4vw;
  color: black;
  font-weight: 500;
  /* font-family:Roboto-Bold; */
}
.web_tooltip_2nd_text {
  font-size: 1.2vw;
  height: 1.2vw;
  color: black;
  font-weight: 400;
  padding-left: 26px;
  padding-right: 14px;
  padding-bottom: 19px;
  /* margin-left:4%; */
  /* font-family:Roboto-Bold; */
}

.demox {
  height: 700px;
  width: 800px;
  background-color: #ffffff;
}

.cardconatiner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.cardfront {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
}
.cardback {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
}

.modalbackground {
  background-color: transparent;
}

.custom-margin-top {
  /* margin-top: 10%; */
  margin-top: 5%;
}

.card-bottom {
  /* background-color: rgba(223, 223, 223, 0.25); */
  background-color: #f7f7f7;
}

.status-btn {
  font-family: Tungsten;
  width: 70%;
  padding: 0.3rem 0;
  /* padding: 0.4rem 0; */
  /* font-size: 16px; */
  font-size: 15px;
  border-radius: 24px;
  border: solid 2px #41b5c2;
  color: #41b5c2;
  background-color: #ffffff;
  letter-spacing: 1.2px;
}

.status-btn-mobile {
  /* width: 50%; */
  min-width: 20vw;
  /* padding: 0.4rem 0.2rem; */
  padding: 0.4rem 0.5rem;
  font-size: 1rem;
  border-radius: 24px;
  border: solid 2px #41b5c2;
  color: #41b5c2;
  background-color: #ffffff;
}

.th-color {
  color: #454d58;
}

td {
  /*padding: 0.25rem 0.75rem !important;*/
}
.font-14 {
  font-size: 14px;
}
.disabled-btn {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

button {
  outline: none !important;
}

@font-face {
  font-family: Tungsten;
  src: url('../../components/healthqn/CSS/fonts/Oswald-SemiBold.ttf');
  src: url('../../components/healthqn/CSS/fonts/Oswald-Regular.ttf');
}
/* @font-face {
  font-family: Tungsten;
  src: url('../../components/healthqn/CSS/fonts/Tungsten.woff');
  src: url('../../components/healthqn/CSS/fonts/Tungsten.woff2');
  src: url('../../components/healthqn/CSS/fonts/Tungsten.ttf') format('truetype');
} */
.custom-font {
  font-family: Tungsten;
}

#printme {
  display: none;
}
@media print {
  #printme {
    display: block;
  }
}

/* ===========================Left Drawer======================== */

.ld_new_menu_name {
  border: 1px solid red;
  font-size: 22px;
}

.ld_menu_container:hover {
  background-color: #e5e7ea;
  border-right: 5px solid #543379;
  color: #420046 !important;
}

.ld_menu_container:hover > .ld_menu_img {
  /* border: 1px solid red; */
  /* background-image: url('../Images/Drawer/documents icon (active).png');
  height: 28px;
  width: 30px; */
  /* object-fit: contain; */
}

.ld_menu_container:hover > .ld_menu_name {
  font-weight: 900;
  color: red;
  border: 1px solid green;
}

.web_alert_diaglog {
  font-size: 1.6vw;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 2vw 2vw;
  text-align: center;
}
.web_modal_close_button {
  margin-top: 3vw;
  background-color: #41b5c2;
  color: white;
  padding: 0.5vw 2vw;
  cursor: pointer;
}
.modal_text {
  font-size: 1.6vw;
}

.handcurserclass {
  cursor: pointer;
}

/* =======================ModalAlert====================== */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .web_alert_diaglog {
    font-size: 3.6vw;
    font-weight: 400;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 6vw 2vw;
    text-align: center;

  }
  .web_modal_close_button {

    margin-top: 3vw;
    background-color: #41b5c2;
    color: white;
    padding: 0.5vw 2vw;

    cursor: pointer;
  }
  .modal_text{
    font-size: 3.6vw;
  }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .web_alert_diaglog {
    font-size: 1.6vw;
    font-weight: 400;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 2vw 2vw;
    text-align: center;
  }
  .web_modal_close_button {

    margin-top: 3vw;
    background-color: #41b5c2;
    color: white;
    padding: 0.5vw 2vw;

    cursor: pointer;
  }
  .modal_text{
    font-size: 1.6vw;
  }
} */

/* my needs */
.title {
  width: 91px;
  height: 18px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

.cellColor {
  color: #8c827a !important;
}

.dataCellColor {
  font-size: 16px;
  color: #333333 !important;
  font-family: Roboto;
  font-weight: 400;
}

.innerTh {
  /*font-size: 12px !important;*/
  /*color: rgba(0, 0, 0, 0.56) !important;*/
  /*font-family: Roboto !important;*/
  /*font-weight: 400 !important;*/
}
.shareNeeds {
  padding: 1px !important;
}
.innerTd {
  font-size: 13px !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.85 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.uname {
  color: #5f2161;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  margin-top: 10px;
}

.font-roboto-reg {
  font-family: Roboto;
  font-weight: 400 !important;
}

.font-roboto-medium {
  font-family: Roboto;
  font-weight: 500 !important;
}

.font-roboto-bold {
  font-family: Roboto;
  font-weight: 600 !important;
}

/* all input back */
.inputbg {
  /* background-color: #e5e5e5; */
  /* background-color: #bdbdbd; */
  background-color: #f1f1f1;
}

@media only screen and (min-width: 768px) {
  .card-content {
    /* min-height: 50vh !important; */
    min-height: 50vh !important;
    overflow: hidden !important;
    overflow-y: auto !important;
  }
}
/* @media (min-width: 1024px) {
  .form-label {
    font-size: 13px;
  }
} */

/* @media only screen and (max-device-width: 480px) {
  html { font-size: 75%; }

  .description{
    font-size: 16px !important;
  }
  .form-label {
    font-size: 11px !important;
  }


} */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .medical_donebtn_mobile {
    font-family: Tungsten;
    width: 31vw;
    height: 32px;
    border-radius: 20px;
    border: 1px solid;
    background-color: #41b5c2;
    font-size: 11px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* tell us about your heath css */
/* bold titles in enroll family forms */
.custom-subtitle {
  font-family: 'Roboto';
  /* font-size: 14px; */
  font-size: 0.875rem;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 18px;
}

/* description in enroll family MQ module */
.description {
  font-family: Roboto;
  font-weight: 400 !important;
  /* font-size: 15px; */
  font-size: 0.9375rem;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
}

/* mq module all form labels */
.form-label {
  font-family: Roboto;
  font-weight: 400 !important;
  /* font-size: 14px; */
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.modal_close_div {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  /* border:1px solid red; */
}

.modal_close_cursor {
  cursor: pointer;
  width: 1.3rem;
  height: 1.3rem;
}
.mqalert_modal_text {
  padding: 0.5rem 1rem;
  /* padding-bottom: 1rem; */
  /* font-size: 14px;
  font-weight: 600; */
  /* border-bottom: 1px solid lightgrey; */

  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.mqalert_button_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.5rem;
  border-top: 1px solid lightgrey;
}

.mqalert_button {
  border: none;
  height: 2rem;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
  margin-right: 1rem;
  background-color: rgb(83, 50, 120);
  color: white;
  border-radius: 4px;
}

.mqalert_button:focus {
  border: none;
  outline-width: 0px;
}
.verionno_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  padding: 10px;
}

.cardwallet_back {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */

  /* width: 145px;
  height: 145px; */
  width: 11vw;
  height: 11vw;
  /* height: 145px; */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  background-color: #ffffff;
  /* margin-top: 20px; */
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  /* margin: 10px; */
  margin: 0.5vw 0.5vw;
  /* cursor: pointer; */
}
.cardwallet_back_notification {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */

  /* width: 145px;
  height: 145px; */
  width: 23vw;
  /* height: 14vw; */
  height: 23vw;
  /* height: 145px; */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
  /* background-color: #ffffff; */
  background-color: #f7f6f1;

  /* margin-top: 20px; */
  /* margin-left: 12px; */
  /* margin-right: 20px; */
  /* margin: 10px; */
  /* margin: 0 0.5vw 0.5vw 0.5vw; */
  /* cursor: pointer; */
}

.cardwallet_back_text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  /* border:1px solid red; */
  padding: 1vw 1.5vw;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #4f4f4f;
  /* margin-top: 1vw; */
  width: 100%;
  background-color: rgba(234, 232, 219, 0.4);
}
.cardwallet_back_text:hover {
  background-color: #5f2161;
  color: white;
  cursor: pointer;
}

/*--------- Collapse Table View CSS -----------*/
.tableContainer {
  box-shadow: none !important;
}
.tableHeadRow {
  border-bottom: 2px solid #8c827a;
}
.tableHeadRow .tableHeader {
  padding: 5px 3px;
  font-weight: normal;
  color: #454d58;
  z-index: 1;
}
.rowTable .rowTableCell {
  padding: 5px;
  color: #000000;
  font-size: 16px;
}
.rowTable .tableExpandIcon {
  z-index: 0;
}
.blankCell {
  width: 6%;
}
.collapseTableContainer {
  margin: 10px 0;
}
.collapseTableContainer .innerTh {
  padding: 5px;
  color: #454d58;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  border: none;
}
.cardwallet_onover_backdivMain {
  /* border: 1px solid red; */
  /* display: flex; */
  align-items: center;
  padding: 0.7vw 0.8vw;
  /* border-bottom: solid 1px #eeeeee; */
}
.cardwallet_onover_backdiv {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding: 0.85vw 0.8vw;
  border-bottom: solid 1px #eeeeee;
  cursor: pointer;
  /* background-color: rgba(234, 232, 219, 0.4); */
}
.notification_details_mainDiv {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  /* padding: 20px 2px; */
  padding: 20px;
  border-bottom: solid 1px #eeeeee;
  cursor: pointer;
}
.notification_details_seeAll {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  /* padding: 20px 2px; */
  padding: 20px;
  border-bottom: solid 1px #eeeeee;
}
.notification_details_mainDiv_read {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  /* padding: 20px 2px; */
  padding: 20px;
  border-bottom: solid 1px #eeeeee;
  cursor: pointer;
  background-color: #decdf1;
}
.data_not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
  height: 300px;
}
.cardwaalet_img_backside {
  width: 2.5vw;
}
.cardwaalet_img_backside1 {
  width: 1.8vw;
}
.Notification_category_img {
  /* width: 1.8vw; */
  width: auto;
}
.Notification_category_img_details {
  width: 35px;
}
.cardwallet_label_backside {
  /* border: 1px solid red; */
  flex: 1;
  /* margin: 0vw 1.0vw; */
  margin-left: 0.3vw;
  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
} /* @media (min-width: 950px){
  .container{
    margin-top: 10%;
  }
  } */

.notification_label_backside {
  /* border: 1px solid red; */
  /* flex: 1; */
  /* margin: 0vw 1.0vw; */
  margin-left: 0.3vw;
  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
}
.notification_label_backside1 {
  margin-left: 0.3vw;
  font-size: 1.1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
}
.notification_label_message {
  /* border: 1px solid red; */
  /* flex: 1; */
  /* margin: 0vw 1.0vw; */
  margin-left: 0.3vw;
  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #5f2161;
}
.viewAll {
  margin-bottom: 5px;
  /* font-size: 0.7vw; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  /* color: #424141; */
  color: #8c827a;
}
.viewAll1 {
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #8c827a;
}
.notification_category_label {
  text-align: left;
  margin-bottom: 5px;
  /* font-size: 0.7vw; */
  font-size: 12px;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  /* color: #424141; */
  color: #8c827a;
}

.notification_category_title{
  color: #4e4e4e;
  display:flex;
}

.notification_category_Message {
  /* border: 1px solid red; */
  flex: 1;
  /* margin: 0vw 1.0vw; */
  margin-left: 15px;
  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  text-align: left;
  font-size: 0.9vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #4e4e4e;
}
.notification_category_label_details {
  text-align: left;
  /* margin-left: 10px; */
  /* border: 1px solid red; */
  /* flex: 1; */
  /* margin: 0vw 1.0vw; */
  /* margin-left: 0.3vw; */
  margin-bottom: 5px;
  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 12px;

  /* font-size: 1.1vw; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: #8c827a;
}
.notification_category_Message_details {
  width: 100%;
  /* border: 1px solid red; */
  /* flex: 1; */
  /* margin: 0vw 1.0vw; */
  margin-left: 1.2vw; /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  font-size: 12px;
  /* font-size: 0.9vw; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #4e4e4e;
  padding-top: 5px;
}
.notification_details_mainDiv:hover {
  background-color: #eeeeee;
}
.notification_details_message {
  /* font-size: 1.1vw; */
  font-size: 14px;
  padding-bottom: 6px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #4e4e4e;
}
.notification_details_Date {
  /* margin-left:20px; */
  font-size: 12px;
  float: right;
  /* margin-left: 50px */
}
.notification_details_Time {
  margin-left: 20px;
  font-size: 12px;
}

.tempModalTxt {
  margin: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.tempModal {
  font-size: 1.6vw;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 2vw 2vw;
  text-align: center;
}

/* ------------Web footer--------------------- */
.webfooter {
  font-size: 12px;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 2vw;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}
.webfooterdesktop {
  font-size: 12px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 2vw;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}
.Value {
  width: 276px;
  height: 52px;
  flex-grow: 0;
  margin: 6px 16px 0 0;
  font-family: Roboto;
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: left;
  color: #41b5c2;
}

.Subscribed-Program {
  width: 292px;
  height: 12px;
  flex-grow: 0;
  margin: 0 0 6px;
  font-family: Roboto;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #8c827a;
}

.Title {
  width: 157px;
  height: 17px;
  flex-grow: 0;
  margin: 0 0 5px;
  font-family: Roboto;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #8c827a;
}

.number1 {
  width: 78.5px;
  height: 46px;
  flex-grow: 0;
  /* margin: 5px 78.5px 0 0; */
  font-family: Roboto;
  font-size: 1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e !important;
}

.The-UHF-Curam-Wellness-Center-1 {
  /* width: 303px; */
  height: 278px;
  font-family: Roboto;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.15px;
  text-align: left;
  color: #4f4f4f;
}

@media only screen and (max-width: 600px) {
  .The-UHF-Curam-Wellness-Center-1 {
    font-size: 0.875em;
  }
  .Subscribed-Program {
    font-size: 12px;
  }
  .Value {
    font-size: 20px;
  }
  .Title {
    font-size: 12px;
  }
  .number1 {
    font-size: 14px;
  }
}

.The-UHF-Curam-Wellness-Center-1 .text-style-1 {
  font-weight: 600;
}

.The-UHF-Curam-Wellness-Center-1 .text-style-2 {
  font-weight: 600;
  color: #41b5c2 !important;
}

.text-style-2 > a:hover {
  color: #41b5c2 !important;
}

img.CuramLife {
  width: 50px;
  height: 50px;
  /* margin: 0 6px 12px; */
  object-fit: contain;
}

::-webkit-scrollbar {
  width: 10px !important;
}

.contact_infomiddiv::-webkit-scrollbar-thumb {
  background-color: #cccccc !important;
  /* outline: 1px solid rgb(34, 102, 170) !important; */
}

.documents_infomiddiv::-webkit-scrollbar-thumb {
  background-color: #cccccc !important;
  /* outline: 1px solid rgb(34, 102, 170) !important; */
}

.bg_content {
  width: 340px;
  height: 300px;
  margin: 12px 0 0;
  padding: 24px 11px 3px 24px;
  background-color: rgba(234, 232, 219, 0.4);
}

.Total-members-in-community {
  width: 100%;
  /* height: 29px; */
  flex-grow: 0;
  margin: 0 0 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c827a;
  margin-bottom: 10px;
}

.Value_community_card {
  width: 81px;
  /* height: 29px; */
  flex-grow: 0;
  margin: 5px 10px 0 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: left;
  color: #304d63;
}

.members {
  width: 56.7px;
  /* height: 12.8px; */
  flex-grow: 0;
  margin: 21.7px 7.1px 0 9.1px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c827a;
}

.chartSpan {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #5f2161;
}

.needs-submitted {
  flex-grow: 0;
  font-family: Roboto;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c827a;
}
